message-entry {
  background-color: var(--rlcb-light-grey);
  padding: 1em 1em 1.25em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  expandable-textarea {
    font-family: inherit;
    font-size: inherit;
    width: 100%;
    height: 100%;
    border: 1px solid var(--rlcb-med-grey);
    background: white;
    border-radius: 5px;
    margin: 0;
    overflow: hidden;
    line-height: 130%;
    min-height: 4.4em;
    max-height: 5.7em;
    overflow-y: auto;
    box-sizing: content-box;
    position: relative;

    &.placeholder:before {
      content: 'How can I help you?';
      position: absolute;
      top: 50%;
      left: .625rem;
      transform: translateY(-50%);
      color: #ccc;
    }

    &:focus-within {
      outline: 1px solid var(--rlcb-med-grey);
    }

    >div>div:first-child {
      /*transform: translateY(1.7em);*/
      overflow-x: hidden !important;
      /*transform: translateY(17px) !important;*/
      margin-bottom: 0 !important;
    }

    >div>div:nth-child(2) {
      display: none;
    }

    text-area {
      height: 100%;
      display: block;
      outline: 0 !important;
      /*overflow-y: auto;*/
      padding: .25rem 2.625rem .25rem .625rem;
      white-space: pre-wrap;
      font-size: 1em;
    }
  }

  ::placeholder {
    position: relative;
    top: .5625em;
    color: #cccccc
  }
}