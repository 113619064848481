@keyframes loading-1 {
  from {
    top: -.375em;
    opacity: 1;
  }

  33% {
    top: 0;
    opacity: .2;
  }

  67% {
    top: 0;
    opacity: .5;
  }

  to {
    top: -.375em;
    opacity: 1;
  }
}

@keyframes loading-2 {
  from {
    top: 0;
    opacity: .5;
  }

  33% {
    top: -.375em;
    opacity: 1;
  }

  67% {
    top: 0;
    opacity: .2;
  }

  to {
    top: 0;
    opacity: .5;
  }
}

@keyframes loading-3 {
  from {
    top: 0;
    opacity: .2;
  }

  33% {
    top: 0;
    opacity: .5;
  }

  67% {
    top: -.375em;
    opacity: 1;
  }

  to {
    top: 0;
    opacity: .2;
  }
}

.content .loading {
  height: 1em;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 .25em;

  span {
    position: relative;
    display: block;
    height: .625em;
    width: .625em;
    margin: .25em .125em 0;
    background-color: var(--rlcb-primary);
    border-radius: 50%;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  span:nth-child(1){
    animation-name: loading-1;
  }

  span:nth-child(2){
    animation-name: loading-2;
  }

  span:nth-child(3){
    animation-name: loading-3;
  }
}