rlcb-message {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: .6em;

  .content {
    padding: .625em .75em .75em;
    background-color: var(--rlcb-light-grey);
    border-radius: 0px .25em .25em .25em;
    color: var(--rlcb-dark-grey);
    white-space: pre-wrap;
    font-size: 1em;
  }

  &.user rlcb-avatar,
  &.user .content {
    background-color: var(--rlcb-primary);
    color: white;
  }
}