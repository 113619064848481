#rlcb-send {
  position: absolute;
  top: 48%;
  right: 1.6em;
  transform: translateY(-50%);
  color: var(--rlcb-primary);
  transition: color .1s ease-out;
  cursor: pointer;
  background-color: transparent;

  &:not(.disabled):hover {
    color: var(--rlcb-primary-light);
  }

  &.disabled {
    color: #cccccc;
    cursor: not-allowed;
  }
}

