rlcb-datetime-divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0 0 1em;

  p {
    display: block;
    background-color: var(--rlcb-primary-light);
    border-radius: 5px;
    padding: .5em 1.25em;
    line-height: 100%;
    font-size: 14px;
    margin: 0 .5em;
    flex-grow: 0;
    color: var(--rlcb-primary);
  }

  rlcb-divider {
    display: block;
    height: 2px;
    width: 10px;
    flex-grow: 1;
    background-color: var(--rlcb-primary-light);
    position: relative;
    overflow: visible;

    &::before {
      content: '';
      height: 15px;
      width: 15px;
      background-color: var(--rlcb-primary-light);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }

    &.left::before {
      left: 0;
    }

    &.right::before {
      right: 0;
    }
  }

  
}