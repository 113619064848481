message-list {
  flex-grow: 1;
  margin: .5em 0;
  justify-content: flex-start;
  overflow-y: auto;

  rlcb-message,
  rlcb-datetime-divider {
    padding-right: .75em;
  }

  * {
    scroll-behavior: smooth;
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      padding: .5em;
      background-color: var(--rlcb-light-grey);
      border-radius: 50%;
    }
  }
}