powered-by {
  padding: 1em .5em .25em;
  text-align: center;
  line-height: 90%;
  color: #CCCCCC;
  display: flex;
  justify-content: center;

  svg {
    margin-right: .25em;
    color: #FFD500;
  }

  span {
    text-transform: uppercase;
    font-weight: bold;
  }
}