rlcb-avatar {
  margin-right: .5em;
  background-color: var(--rlcb-light-grey);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
}